<template>
  <v-card-text class="mt-5 mb-3 px-md-12 px-sm-5">
    <app-form ref="form" :fields="fields" :submitButton="submitButton" @submitted="handleSubmit"
    disableCancelButton footerClass="col-12 text-center mt-5" @selection-changed="onSelectionChange"
    @text-changed="workingHoursChanged"/>
  </v-card-text>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import {userRoles} from 'constants';

export default {
  name: 'EditWorkInfo',
  data() {
    return {
      alreadyWarned: false,
      fullTimeWorkingDay: '',
      submitButton: {
        label: this.$t('actions.save')
      },
      fields: {
        jiraUsername: {
          type: 'text',
          key: 'jiraUsername',
          value: '',
          label: this.$t('models.company.attributes.jiraUsername'),
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        jiraPassword: {
          type: 'password',
          key: 'jiraPassword',
          value: '',
          label: this.$t('models.company.attributes.jiraPassword'),
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        role: {
          type: 'select',
          key: 'role',
          value: '',
          options: [],
          label: this.$t('models.user.attributes.role'),
          rules: [{name: 'requiredSelect'}],
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        reporterId: {
          type: 'autocomplete',
          key: 'reporterId',
          value: '',
          options: [],
          label: this.$t('models.user.attributes.reporterId'),
          itemText: 'name',
          itemValue: 'id',
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        employeeId: {
          type: 'number',
          key: 'employeeId',
          value: '',
          rules: [{name: 'required'}],
          label: this.$t('models.user.attributes.employeeId'),
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        jobTitle: {
          type: 'text',
          key: 'jobTitle',
          value: '',
          label: this.$t('models.user.attributes.title'),
          rules: [{name: 'required'}],
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        office: {
          type: 'select',
          key: 'officeId',
          value: '',
          options: [],
          itemText: 'name',
          itemValue: 'id',
          rules: [{name: 'requiredSelect'}],
          trackable: true,
          label: this.$t('models.user.attributes.office'),
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        timezone: {
          type: 'text',
          key: 'timezone',
          disabled: true,
          value: '',
          label: this.$t('models.user.attributes.timezone'),
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        department: {
          type: 'select',
          key: 'departmentId',
          value: '',
          options: [],
          itemText: 'name',
          itemValue: 'id',
          label: this.$t('models.user.attributes.department'),
          rules: [{name: 'requiredSelect'}],
          trackable: true,
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        teams: {
          type: 'select',
          key: 'teams',
          value: '',
          options: [],
          itemText: 'name',
          itemValue: 'id',
          multiple: true,
          label: this.$t('models.user.attributes.team'),
          chips: true,
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        jobType: {
          type: 'select',
          key: 'jobType',
          value: '',
          options: [{key: 'full', text: 'Full Time'}, {key: 'part', text: 'Part Time'}],
          trackable: true,
          label: this.$t('models.user.attributes.jobType'),
          rules: [{name: 'requiredSelect'}],
          itemText: 'text',
          itemValue: 'key',
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        workingHoursPerDay: {
          type: 'number',
          key: 'workingHoursPerDay',
          value: '',
          trackable: true,
          label: this.$t('models.user.attributes.workingHoursPerDay'),
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        level: {
          type: 'number',
          key: 'level',
          value: '',
          label: this.$t('models.user.attributes.level'),
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        startDate: {
          type: 'yearDatePicker',
          key: 'startDate',
          value: '',
          placeholder: this.$t('models.user.attributes.startDate'),
          label: this.$t('models.user.attributes.startDate'),
          classes: 'col-md-6 col-sm-12 col-12 py-0',
          serverErrors: []
        }
      }
    };
  },
  computed: {
    ...mapGetters(['departments', 'teams', 'offices', 'user', 'users', 'isAdmin']),
    roles() {
      const $this = this;
      const roles = [];
      for (const key in userRoles) {
        if (Object.prototype.hasOwnProperty.call(userRoles, key)) {
          roles.push({text: $this.$t(`models.user.roles.${userRoles[key]}`), key: `${userRoles[key]}`});
        }
      }
      return roles;
    }
  },
  methods: {
    ...mapActions(['updateUser', 'getDepartments', 'getTeams', 'getOffices', 'getUsers', 'getUser']),
    handleSubmit(data) {
      const userData = new FormData();
      for (const key in data) {
        if (data[key]) {
          if (key === 'teams') {
            if (data[key].length === 0) {
              userData.append('teams_users_attributes', []);
            } else {
              data[key].forEach((value, index) => {
                userData.append(`teams_users_attributes[${index}][team_id]`, value);
              });
            }
          } else {
            userData.append(key, data[key]);
          }
        }
      }
      this.updateUser({userId: this.$route.params.uuid, userData: userData}).then((response) => {
        if (!this.successCode(response.status)) {
          this.displayInlineServerErrors(this.fields, response.errors);
        }
      });
    },
    onSelectionChange($event) {
      if ($event.key === 'officeId') {
        const office = this.offices.items.find(({id}) => id === $event.value);
        this.fields.timezone.value = office.timezone;
        this.fullTimeWorkingDay = office.fullTimeWorkingDay;
        this.fields.workingHoursPerDay.value = office.fullTimeWorkingDay;
      } else if ($event.key === 'jobType') {
        this.workingHoursChanged();
        switch ($event.value) {
        case 'full':
          this.fields.workingHoursPerDay.value = this.fullTimeWorkingDay;
          this.fields.workingHoursPerDay.hidden = true;
          this.fields.workingHoursPerDay.rules = [{
            name: 'required'
          }, {
            name: 'greaterThanOrEqual',
            value: 1
          }];
          break;
        default:
          this.fields.workingHoursPerDay.hidden = false;
          this.fields.workingHoursPerDay.rules = [{
            name: 'required'
          }, {
            name: 'greaterThanOrEqual',
            value: 1
          }, {
            name: 'lessThan',
            value: this.fields.workingHoursPerDay.value
          }];
          break;
        }
      }
    },
    workingHoursChanged($event) {
      if (!this.alreadyWarned) {
        this.$confirm(this.$t('messages.confirmation.changeWorkingHours'), {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }).then((confirmed) => {
          if (!confirmed) {
            this.fields.workingHoursPerDay.value = this.user.workingHoursPerDay || this.fullTimeWorkingDay;
            this.fields.jobType.value = this.user.jobType;

            this.fields.workingHoursPerDay.hidden = this.user.jobType === 'full' ? true : false;
          }
        });
        this.alreadyWarned = true;
      }
    },
    setDefaultValues() {
      this._.forEach(this.fields, (field) => {
        field.disabled = !this.isAdmin && field.key !== 'jiraUsername' && field.key !== 'jiraPassword';
        switch (field.key) {
        case 'role':
          field.value = this.user.role? this.user.role : null;
          break;
        case 'departmentId':
          field.value = this.user.department ? `${this.user.department.id}` : null;
          break;
        case 'officeId':
          field.value = this.user.office ? `${this.user.office.id}` : null;
          break;
        case 'workingHoursPerDay':
          field.value = this.user.workingHoursPerDay || this.fullTimeWorkingDay;
          field.hidden = this.user.jobType && (this.user.jobType === 'full') ? true : false;
          break;
        case 'timezone':
          field.value = this.user.office ? this.user.office.timezone : null;
          break;
        case 'teams':
          field.value = this.user.teams ? this._.map(this.user.teams, (team) => `${team.id}`) : [];
          break;
        case 'startDate':
          field.value = this.user.startDate;
          break;
        default:
          field.value = this.user[field.key] ? `${this.user[field.key]}` : '';
          break;
        }
      });
    }
  },
  created() {
    Promise.all([
      this.getUser({uuid: this.$route.params.uuid}),
      this.getOffices({fields: ['name', 'timezone', 'fullTimeWorkingDay'], sortBy: ['name']}),
      this.getDepartments({fields: ['name'], sortBy: ['name']}),
      this.getTeams({fields: ['name'], sortBy: ['name']}),
      this.getUsers({fields: ['firstName', 'lastName'], sortBy: ['firstName']})
    ]).then(() => {
      this.fields.department.options = this.departments.items;
      this.fields.teams.options = this.teams.items;
      this.fields.office.options = this.offices.items;
      this.fields.role.options = this.roles;
      this.fields.reporterId.options = this.users.items.map((user) => ({id: `${user.id}`, name: user.name}));
      this.fullTimeWorkingDay = this.user.office ? `${this.user.office.fullTimeWorkingDay}` : null;
      this.setDefaultValues();
    });
  }
};
</script>

